import { RegistrationOfChargeContactDtoContactType } from "./service-proxies/service-proxies";
import { ILabelPrinterSettings } from "../app/registration/entity-documents/entity-documents.component";

export class AppConsts {
    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static titleSuffix: string;
    static timeoutInSeconds: number;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Criss'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static readonly DocumentManagementError = {
        CertificateNotFound: "No Certificate Found"
    };

    static readonly NameReservationError = {
        SubmitNewMsg: "Please submit a new name.",
        ValidationErrorIBCINSSaveEntity: "You don't have permission to register {0} Entity. Please contact the system administrator.",
        RequestForCRApprovalMsg: "Please click 'Save and Complete' if you wish to submit a request for Registry Approval or submit a new name",
        SubmitOverrideWarning: "Do you want to override the warnings?",
        RequestForCRApproval: "Do you want to submit a request for Registry Approval?",
        ProhibitedName: "This name contains the prohibited word: ",
        ReservedForSameRA: "This name was previously reserved for the same Registered Agent",
        ExactSimilarName: "This name contains exact match of a entity that is in our system: ",
        SimilarName: "This name contains similar entity name that is on our Registers: ",
        RestrictedWords: "This name contains the restricted word: ",
        CompanyTypeRequired: "Entity Type is required",
        CompanyNameRequired: "Entity Name is required",
        RegisteredAgentRequired: "Registered Agent is required",
        ReservationDurationRequired: "Reservation Duration is required",
        SuffixRequired: "Suffix is required"
    }

    static readonly RaManagementErrors = {
        NameRequired: "RA Name is required",
        BusinessPhoneRequired: "Business Phone is required",
        BusinessPhoneFormat: "Business Phone is not in correct format",
        CellNumberFormat: "Cell Number is not in correct format",
        FaxNumberFormat: "Fax Number is not in correct format",
        StatusRequired: "Status is required",
        AddressLine1Required: "Address Line 1 is required",
        CountryRequired: "Country is required",
        EmailFormat: "Email is not in correct format",
        LicenseRenewalDate: "Next Renewal Date is required for selected licenses",
    };

    static readonly UserManagementError = {
        FirstNameRequired: "First Name is required",
        LastNameRequired: "Last Name is required",
        UserNameRequired: "User Name is required",
        EmailRequired: "Email is required",
        UserGroupRequired: "User Group is required",
        EmailFormat: "Email is not in correct format",
        BusinessPhoneNumberRequired: "Business Phone Number is required",
        RegisteredAgentRequired: "Registered Agent is required"
    };

    static readonly MakePaymentError = {
        BankRequired: "Bank is required",
        ChequeRequired: "Cheque Number is required",
        PaymentDateRequired: "Transaction Date is required",
        PaymentAmountRequired: "Payment Amount is required",
        PaymentTypeRequired: "Payment Type is required",
        MinBalanceRequired: "Minimum Balance is required"
    };

    static readonly ServiceRequestErrors = {
        NameRequired: "Name is required",
        Address1Required: "Address 1 is required",
        PhoneNumberRequired: "Phone Number is required",
        CountryRequired: "Country is required",
    }

    static readonly FeeManagementErrors = {
        CodeRequired: "Fee code is required",
        DescriptionRequired: "Fee Description is required",
        StatusRequired: "Status is required",
        ServiceTypeRequired: "Service Type is required",
        AmountUsd: "Flat Amount (in USD) is required",
        AmountEc: "Flat Amount (in EC) is required",
        AmountNotNegative: "Amount cannot be less than 0",
        AccountNumberRequired: "Account Number is required",
        ActivityNameRequired: "Activity Name is required if record entity is selected",
        ServiceTypeNote: "Please select 'Entity Type' first",
        CompanyTypeRequired: "Entity Type is required"
    };

    static readonly FaManagementErrors = {
        AccountNumberRequired: "Account Number is required",
        DescriptionRequired: "Description is required",
        StatusRequired: "Status is required",
        AccountTypeRequired: "Account Type is required"
    };

    static readonly BusinessActivityErrors = {
        CompanyTypeRequired: "Entity Type is required",
        BusinessActivityRequired: "Business Activity is required",
        StatusRequired: "Status is required",
    };

    static readonly SecurityMessages = {
        PasswordDontMatch: "New password does not match with Confirm new password",
        PasswordResetSuccess: "Your password has been reset",
        UpdateSuccess: "Profile updated successfully",
        SecurityQuestionsUpdateSuccess: "Security questions updated successfully",
        CurPasswordRequired: "Current Password is required",
        NewPasswordRequired: "New Password is required",
        ConfirmPasswordRequired: "Confirm Password is required"
    };

    static readonly TransactionMessages = {
        NoFeeSelected: "Please select at least one Fee",
        TransactionUpdated: "Transaction Updated Successfully!",
        TransactionPosted: "Transaction Posted Successfully!",
        RefundRequestSuccess: "Refund updated Successfully!",
        RefundRequestSuccessRA: "Your request for refund has been successfully submitted for Registry Approval. It might take a few days for your request to be processed.",
        AddFund_Success: "Funds added successfully!",
        ReduceFund_Success: "Funds reduced successfully!",
        TransactionCreated: "The payment has been submitted successfully. Request has been updated.",
        TransactionCreated_NeedSave: "The transaction has been submitted successfully! Please Click 'Save & Complete' to reserve the name",
        PendingTransaction_CR: "The payment has been submitted successfully and pending the Cheque Verification process",
        PendingTransaction_RA: "The payment has been submitted successfully. Please allow sometime for the Cheque Verification process or contact the Registry for assistance.",
        SaveChequeTransaction: "The service can't be completed until the Cheque is verified, the verification process can take few days.",
        RefundReasonRequired: "Refund Reason is required.",
        SaveBeforeMakePayment: "Fee selection was changed. Please click 'Save' before submitting a payment",
        NoPaymentWhenPendingChequeVerification: "The request is 'Pending Cheque Verification'. Additional payment can't be paid until the original Cheque is verified.",
        CompanyRegistration_RA_AtleastOnePayment: "Registration fees must be paid in order to submit the request."
    };

    static readonly ConfirmationMessages = {
        Are_You_Sure_: "Are You Sure?",
        Are_you_sure_you_want_to_proceed_: "Are you sure you want to proceed?",
        Generic_Delete: "You're about to delete a record",
        Generic_Update: "You're about to update a record",
        DiscardChanges: "Discard Changes",
        DiscardChangesMessage: "Are you sure that you want to discard changes?",
        DiscardChangesMessage_Warn: "All unsaved changes were discarded",
        MasterData_RASave: "You're about to save a registered agent",
        MasterData_RAUpdate: "You're about to update a registered agent",
        MasterData_FeeSave: "You're about to save a Fee",
        MasterData_FeeUpdate: "You're about to update a Fee",
        MasterData_AccountSave: "You're about to save a financial account",
        MasterData_AccountUpdate: "You're about to update a financial account",
        MasterData_BusinessActivitySave: "You're about to save a business activity",
        MasterData_BusinessActivityUpdate: "You're about to update a business activity",
        NameReservation_Save: "You're about to save changes",
        Request_SaveFromMakePayment: "You're about to save changes, before submitting a payment",
        NameReservation_SaveFromMakePayment: "<p>You're about to save changes and submit payment.</p><p>Please make sure the name is provided in the letter case required in registration (Capital and Small letters). The name can't be edited after this step.</p>",
        NameReservation_SaveAndComplete: "<p>You're about to complete a name reservation.</p><p>Please make sure the name is provided in the letter case required in registration (Capital and Small letters). The name can't be edited after this step.</p><p>When a name has been reserved and no Certificate of Name Reservation has been issued, the Registrars reserve the right to reject any name submitted, prior to incorporation, should they become aware of circumstances which would make the use of the name undesirable or it is in the best interests of the jurisdiction to do so.</p>",
        NameReservation_Terminate: "You're about to reject a name reservation",
        NameReservation_OverrideTitle: "Override Warnings",
        NameReservation_Override: "Warnings",
        NameReservation_OverrideAndSave: "All warnings will be overridden and name will be reserved.",
        NameReservation_OverrideAndSaveOnly: "All warnings will be overridden and details will be updated.",
        NameReservation_RequestForManualApproval: "Please request for a manual approval from CR",
        NameReservation_RequestForManualApprovalAndSave: "Request will be submitted for Registry Approval",
        NameReservation_RequestForManualApprovalSuccessMsg: "Request is submitted for Registry Approval",
        NameReservation_10DayRenew: "Do you want to proceed with extending the name reservation for another 10 days?",
        NameReservation_120DayRenew: "Do you want to proceed with extending the name reservation for another 120 days?",
        NameReservation_RenewSuccess: "Name reservation renewed successfully!",
        NameReservation_ReserveSuccess: "Name is reserved successfully!",
        NameReservation_RequestCompleteSuccess: "Request is completed successfully!",
        NameReservation_RequestPendingCertificate: "Please fill certificate parameters to complete the request.",
        NameReservation_SaveCertificateMessage: "By saving the signature, you confirm that the certificate parameters have been reviewed.",
        NameReservation_Certificate_AddedSuccess: "Certificate parameters were successfully added! Please click 'Save and Complete' to complete the name reservation.",
        NameReservation_FootNote_RaPortal: "*For 120 Days Name Reservation, the name will be automatically reserved after submitting the payment, and the request will be completed by the Registry after issuing the name reservation certificate.",
        NameReservation_RenewalUserNotAuthorized: "120 Days Renewal is associated with fees. Only the users authorized to make payment can perform this action.",
        Certificate_Parameter_UpdatedSuccess: "Certificate parameters are updated!",
        Transaction_MakeRefund: "Do you want to proceed with refund?",
        Transaction_Update: "You're about to update a transaction",
        Transaction_Post: "You're about to post a transaction",
        Transaction_Void: "The transaction and receipt will be void. New payment will be required for the associated service requests.",
        Request_Reject: "You're about to reject the request",
        You_re_about_to_reject_the_request__the_request_can_t_be_reopened_and_any_paid_fees_may_not_be_refunded_: "You're about to reject the request, the request can't be reopened and any paid fees may not be refunded.",
        You_re_about_to_discard_the_request_: "You're about to discard the request.",
        Request_Discard_CompanyRegistration: "You're about to discard the request, the request can't be reopened and any paid fees may not be refunded.",
        Refund_Complete: "The refund amount will be credited into the RA prepaid account, the financial accounts will be updated",
        UserSave: "You're about to save a new user",
        UserUpdate: "You're about to update a user",
        ResetPasswordConfirmation: "A temporary password has been sent to your email address!",
        ResetPasswordError: "An error occurred while processing the request. Please try again!",
        CompanyRegistration_Complete: "You're about to complete a Registration request.",
        CompanyRegistration_Save: "You're about to save changes to registration details.",
        CompanyRegistration_Submit: "You're about to submit a Registration Request for processing. The request can not be edited after submission.",
        Please_make_sure_to_the_upload_required_documents_before_submitting_the_request__Missing_documents_can_cause_the_request_to_take_longer_to_be_processed_or_even_denied_: "Please make sure to upload the required documents before submitting the request. Missing documents can cause the request to take longer to be processed or even denied.",
        CommentAddedSuccess: "Comment is successfully added!",
        Batch_SaveForAddEntity: "You're about to save changes, before adding requests.",
        AnnualRenewal_SaveForAddEntity: "You're about to save changes, before adding entities.",
        AnnualRenewal_Terminate: "You're about to reject an annual renewal request.",
        AnnualRenewal_SearchEntities_FootNote: "* Only “Active and Transfer Domicile In” Entities are eligible for Annual Renewal. Please Contact the Registry to reinstate “Struck off” entities.",
        AnnualRenewal_Fees_FootNote: "* The entities will be automatically renewed after verifying the payment; however the request will be completed by the Registry after issuing the required certificates, if any.",
        AnnualRenewal_DeleteEntity_Prompt: "Do you want to proceed with deleting the entity and saving the request?",
        Do_you_want_to_proceed_with_deleting_the_request_and_saving_the_request_: "Do you want to proceed with deleting the request and saving the request?",
        Batch_FootNote: "* Please close the batch in order to proceed with payment.",
        Certificate_IssuedSuccess: "Certificate was issued successfully!",
        You_re_about_to_submit_a_request_for_processing: `You're about to submit a request for processing. The request can not be edited after submission.`,
        NameReservationDenied_Warning: `The name reservation for this registration has been denied.  Please resolve before continuing.`,
        NameReservationDenied_Warning_Title: `Name Reservation Denied`,
    };

    static readonly OtherServiceValidators = [
        { name: 'CompanyType', description: 'Entity Type' },
        { name: 'ServiceType', description: 'Service Type' },
    ];

    static readonly AddRequestsToBatchValidators = [
    ];

    static readonly EntityRegistrationValidators = {
        RegistrationOfCharge: [
            { name: 'ChargeDate', description: "Creation of Charge Date" },
            { name: 'AmountSecured', description: "Amount Secured by Charge" },
            { name: 'Currency', description: "Currency" },
            { name: 'RegistrationDate', description: "Date of Registration" },
            { name: 'DescriptionOfCharge', description: "Description of Charge" },
            { name: 'ChargeeFirstName', description: "Chargee First Name", conditionalRequiredField: 'ChargeeType', conditionalRequiredValue: RegistrationOfChargeContactDtoContactType.Person },
            { name: 'ChargeeLastName', description: "Chargee Last Name", conditionalRequiredField: 'ChargeeType', conditionalRequiredValue: RegistrationOfChargeContactDtoContactType.Person },
            { name: 'ChargeeCompanyName', description: "Chargee Company Name", conditionalRequiredField: 'ChargeeType', conditionalRequiredValue: RegistrationOfChargeContactDtoContactType.Company },
            { name: 'ChargeeAddressLine1', description: "Chargee Address Line 1" },
            { name: 'ChargeeCountry', description: "Chargee Country" },
            { name: 'ChargerFirstName', description: "Charger First Name", conditionalRequiredField: 'ChargerType', conditionalRequiredValue: RegistrationOfChargeContactDtoContactType.Person },
            { name: 'ChargerLastName', description: "Charger Last Name", conditionalRequiredField: 'ChargerType', conditionalRequiredValue: RegistrationOfChargeContactDtoContactType.Person },
            { name: 'ChargerCompanyName', description: "Charger Company Name", conditionalRequiredField: 'ChargerType', conditionalRequiredValue: RegistrationOfChargeContactDtoContactType.Company },
            { name: 'ChargerAddress1', description: "Charger Address Line 1" },
            { name: 'ChargerCountry', description: "Charger Country" },
            { name: 'GoverningLawOfCharge', description: "Governing Law of Charge" },
            { name: 'ChargeStatus', description: "Charge Status" },
            { name: 'DateOfRegistration', description: "Date of Registration" },
            { name: 'DateOfCreation', description: "Date of Creation" },
            { name: 'NatureOfCharge', description: "Nature of Charge" },
            { name: 'ShortParticulars', description: "Short particulars of the property charged" },
        ],
        ManagementAndOfficer: [
            { name: 'ManagerType', description: "Manager Type" },
            { name: 'FirstName', description: "First Name" },
            { name: 'LastName', description: "Last Name" },
            { name: 'Titles', description: "Titles" },
            { name: 'RegistrationDate', description: "Date of Registration" },
            { name: 'AddressLine1', description: "Address Line 1" },
            { name: 'AddressCountry', description: "Address Country" },
            { name: 'DateAppointed', description: "Date Appointed" },
            { name: 'OriginCountry', description: "Country of Origin" },
            { name: 'Nationality', description: "Nationality" },
            { name: 'IdType', description: "ID Type" },
            { name: 'IdNumber', description: "ID Number" },
            { name: 'IdIssuanceDate', description: "ID Issuance Date" },
            { name: 'IdExpirationDate', description: "ID Expiration Date" },
            { name: 'IdIssuingCountry', description: "ID Issuing Country" }
        ],
        EntityActivities: [
            { name: 'ActivityName', description: "Activity Name" },
            { name: 'ActivityDate', description: "Activity Date" },
        ],
        BusinessActivities: [
            { name: 'BusinessActivity', description: "Business Activity" },
        ]
    };

    static readonly DocumentStatusKeys = {
        Denied: "Denied",
        Filed: "Filed",
        Reviewed: "Reviewed",
        Uploaded: "Uploaded",
    };

    static readonly UploadDocumentValidators = [
        { name: "confidentialityKey", description: "Confidentiality" },
        { name: "fileName", description: "File Name" },
        { name: "statusKey", description: "Status" },
        { name: "filingDate", description: "Filing Date", conditionalRequiredField: 'statusKey', conditionalRequiredValue: AppConsts.DocumentStatusKeys.Filed },
        { name: "stampKey", description: "Stamp", conditionalRequiredField: 'statusKey', conditionalRequiredValue: AppConsts.DocumentStatusKeys.Filed },
    ];

    static readonly SecurityAnswersComplexity = [
        "Answer must be at least 4 characters long (after removing all leading and trailing spaces)",
        "Same character/digit cannot be used more than 2 times in consecutively",
        "Same answer can't be used twice"
    ];

    static readonly PasswordComplexity = [
        "At least 10 characters long",
        "At least one upper case character",
        "At least one lower case character",
        "At least one digit",
        "At least one special character",
        "Same character/digit cannot be used more than 2 times consecutively",
        "New password not same as the current password",
        "New password and confirm password not blank and match"
    ];

    static readonly ReservationStatus = {
        Reserved: 'NameReservationStatus.Reserved',
        Pending: 'NameReservationStatus.Pending',
        Registered: 'NameReservationStatus.Registered',
        Expired: 'NameReservationStatus.Expired',
        Denied: 'NameReservationStatus.Denied'
    };

    static readonly UserGroups = {
        FrontDesk: 'Front Desk',
        Accounting: 'Accounting',
        RA: 'Registered Agent'
    };

    // TODO: Change names based on server implementation
    static readonly RegistrationStatus = {
        Active: 'EntityStatus.Active',
        TransferDomicileIn: 'EntityStatus.TransferDomicileIn',
        TransferDomicileOut: 'EntityStatus.TransferDomicileOut',
        Pending: 'EntityStatus.Pending',
        PendingConversion: 'EntityStatus.PendingConversion',
        StruckOff: 'EntityStatus.StruckOff',
        Dissolved: 'EntityStatus.Dissolved',
        Denied: 'EntityStatus.Denied',
        Merged: 'EntityStatus.Merged',
        Converted: 'EntityStatus.Converted',
        Consolidated: 'EntityStatus.Consolidated',
        Terminated: 'EntityStatus.Terminated'
    };

    static Status = {
        Completed: "RequestStatus.Completed",
        Denied: "RequestStatus.Denied",
        PaymentVerified: "RequestStatus.PaymentVerified",
        PendingPayment: "RequestStatus.PendingPayment",
        PendingCertificate: "RequestStatus.PendingCertificate",
        PendingChequeVerification: "RequestStatus.PendingChequeVerification",
        Submitted: "RequestStatus.Submitted",
        PendingReview: "RequestStatus.PendingReview",
        PendingRaAction: "RequestStatus.PendingRegisteredAgentAction",
    }

    static BatchStatus = {
        Open: "Open",
        Closed: "Closed",
        Posted: "Posted",
    }

    static TransactionStatus = {
        Posted: "Posted",
        Pending: "Pending",
        Void: "Void"
    }

    static BooleanDisplayValue = {
        Yes: "Yes",
        No: "No"
    }

    static WalkInValues = {
        All: "All",
        Walkin: "Walk-In",
        RA: "RA"
    }

    static StatusFilters = {
        AnnualRenewal: "AnnualRenewal",
        CompanyRegistration: "CompanyRegistration",
        ServiceRequest: "ServiceRequest",
        ServiceCategory: "ServiceCategory",
        InternalRequestStatus: "InternalRequestStatus",
        ExternalRequestStatus: "ExternalRequestStatus",
        NameReservation: "NameReservation",
        Assignable: "Assignable",
        RequestStatus: "RequestStatus",
        EntityStatus: "EntityStatus",
        InsuranceClassStatus: "InsuranceClassStatus",
        CertificateSignature: "Signature",
        CertificateSeal: "Seals",
        CertificateSignatory: "Signatory ",
        TypesOfSharesAllowed: "TypesOfSharesAllowed",
        ManagerType: "ManagerType",
        IdentificationType: "IdentificationType",
        StatusOfTheChargeKey: "StatusOfTheChargeKey",
        BatchStatus: "BatchStatus",
        Currency: "Currency",
        CertificateStatus: "CertificateStatus"
    }

    static readonly sidebarMenuItems = [
        { name: 'Home', icon: 'fas fa-home', route: '/app/home' },
        { name: 'Name Reservation', icon: 'far fa-address-card', route: '/app/nameReservation' },
        { name: 'Registrations', icon: 'fas fa-landmark', route: '/app/registrations' },
        { name: 'Annual Renewals', icon: 'far fa-clock', route: '/app/annualRenewal' },
        { name: 'Other Services', icon: 'fas fa-briefcase', route: '/app/service', isIncomplete: false },
        { name: 'Transactions', icon: 'fas fa-donate', route: '/app/transactionTabs/transaction' },
        { name: 'Batch Management', icon: 'fas fa-shopping-cart', route: '/app/batch', isIncomplete: false },
        { name: 'User Management', icon: 'fas fa-user-shield', route: '/app/users', data: { permission: 'Menu.User Management' } },
        { name: 'Document Management', icon: 'far fa-folder-open', route: '/app/documentManager/document', isIncomplete: false },
        {
            name: 'Master Data', icon: 'fas fa-cogs', route: '/app/masterData/raManagement',
            data: { permission: 'Portal.Corporate Registry' },
        },
        {
            name: 'Data Migrations', icon: 'fas fa-clone', route: '/app/migration/legacyData',
            data: { permission: 'Portal.Corporate Registry' },
        },
        {
            name: 'Reports', icon: 'fas fa-file-alt', route: '/app/reports/entityStatus', isIncomplete: false,
            data: { permission: 'Portal.Corporate Registry' }
        },
        //{name: 'Logout', icon: 'pi pi-sign-out', route: '#'},
    ];

    static readonly PageTitles = {
        Dashboard: 'Home',
        MasterData: 'Master Data',
        Registration: 'Registration',
        Transaction_Search: 'Transaction',
        Transaction_History: 'Transaction Details',
        Account_Balance: 'RA Prepaid Account',
        Account_History: 'RA Prepaid Account History',
        NameReservation_Search: 'Name Reservation',
        NameReservation_Reserve: 'Reserve a Name',
        AnnualRenewal: 'Annual Renewal',
        AnnualRenewal_Details: 'Annual Renewal Details',
        Refund: 'Refund',
        BatchManagement: 'Batch Management',
        BatchDetails: 'Batch Details',
        UserManagement: 'Users',
        MyProfile: 'My Profile',
        Service: 'Service',
        DataMigration: 'Data Migration',
        DocumentManager: 'Document Manager',
        Reports: 'Reports',
        RaProfile: 'RA Profile'
    }

    // For actions column, add columnId = 'actions' at the end
    static readonly datagridColumnDefinitionTypes = {
        NameReservation: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'request.requestNumber', 'columnName': 'Request No', 'width': '10%' },
            //{'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch Number'},
            { 'columnId': 'referenceNumber', 'sortColumnId': 'referenceNumber', 'columnName': 'Reference No', 'width': '11%' },
            { 'columnId': 'companyFullName', 'sortColumnId': 'companyName', 'columnName': 'Entity Name' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'request.companyTypeName', 'columnName': 'Entity Type' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'request.registeredAgent.Name', 'columnName': 'RA Name' },
            { 'columnId': 'expirationDate', 'isDate': true, 'sortColumnId': 'expirationDate', 'columnName': 'Reservation End Date', 'width': '14%' },
            { 'columnId': 'reservationDurationDays', 'sortColumnId': 'reservationDurationDays', 'columnName': 'Duration', 'width': '9%' },
            { 'columnId': 'reservationStatusName', 'sortColumnId': 'reservationStatusKey', 'columnName': 'Status', 'width': '9%' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        NameReservationRAPortal: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'request.requestNumber', 'columnName': 'Request No', 'width': '10%' },
            //{'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch Number'},
            { 'columnId': 'referenceNumber', 'sortColumnId': 'referenceNumber', 'columnName': 'Reference No', 'width': '11%' },
            { 'columnId': 'companyFullName', 'sortColumnId': 'companyName', 'columnName': 'Entity Name' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'request.CompanyTypeName', 'columnName': 'Entity Type' },
            { 'columnId': 'expirationDate', 'isDate': true, 'sortColumnId': 'expirationDate', 'columnName': 'Reservation End Date', 'width': '14%' },
            { 'columnId': 'reservationDurationDays', 'sortColumnId': 'reservationDurationDays', 'columnName': 'Duration', 'width': '9%' },
            { 'columnId': 'reservationStatusName', 'sortColumnId': 'reservationStatusKey', 'columnName': 'Status', 'width': '9%' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '5%' }
        ],
        Registration: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'Request.RequestNumber', 'columnName': 'Request No', 'width': '10%' },
            //{'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch Number'},
            { 'columnId': 'incorporationNumber', 'sortColumnId': 'EntityDetail.IncorporationDetail.IncorporationNumber', 'columnName': 'Incorporation No', 'width': '11%' },
            { 'columnId': 'companyNameAndSuffix', 'sortColumnId': 'EntityDetail.EntityName.NameReservation.CompanyName', 'columnName': 'Entity Name' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'EntityDetail.EntityName.CompanyTypeKey', 'columnName': 'Entity Type' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'Request.RegisteredAgent.Name', 'columnName': 'RA Name' },
            { 'columnId': 'incorporationDate', 'isAstDate': true, 'sortColumnId': 'EntityDetail.IncorporationDetail.IncorporationDate', 'columnName': 'Incorporation Date', 'width': '13%' },
            { 'columnId': 'annualRenewalDate', 'isDate': true, 'sortColumnId': 'EntityDetail.IncorporationDetail.AnnualRenewalDate', 'columnName': 'Next Annual Renewal Date', 'width': '13%' },
            { 'columnId': 'entityStatusName', 'sortColumnId': 'EntityDetail.IncorporationDetail.EntityStatusKey', 'columnName': 'Status', 'width': '9%' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        RegistrationRA: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'Request.RequestNumber', 'columnName': 'Request No', 'width': '10%' },
            //{'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch Number'},
            { 'columnId': 'incorporationNumber', 'sortColumnId': 'EntityDetail.IncorporationDetail.IncorporationNumber', 'columnName': 'Incorporation No', 'width': '11%' },
            { 'columnId': 'companyNameAndSuffix', 'sortColumnId': 'EntityDetail.EntityName.NameReservation.CompanyName', 'columnName': 'Entity Name' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'EntityDetail.EntityName.CompanyTypeKey', 'columnName': 'Entity Type' },
            { 'columnId': 'incorporationDate', 'isAstDate': true, 'sortColumnId': 'EntityDetail.IncorporationDetail.IncorporationDate', 'columnName': 'Incorporation Date', 'width': '13%' },
            { 'columnId': 'annualRenewalDate', 'isDate': true, 'sortColumnId': 'EntityDetail.IncorporationDetail.AnnualRenewalDate', 'columnName': 'Next Annual Renewal Date', 'width': '13%' },
            { 'columnId': 'entityStatusName', 'sortColumnId': 'EntityDetail.IncorporationDetail.EntityStatusKey', 'columnName': 'Status', 'width': '9%' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        RegistrationOfCharges: [
            { 'columnId': 'dateOfCreationOfCharge', 'sortColumnId': 'dateOfCreationOfCharge', 'isDate': true, 'columnName': 'Date of Charge' },
            { 'columnId': 'registrationDate', 'sortColumnId': 'registrationDate', 'isDate': true, 'columnName': 'Registration Date' },
            { 'columnId': 'descriptionOfCharge', 'sortColumnId': 'descriptionOfCharge', 'columnName': 'Description' },
            { 'columnId': 'nameOfChargee', 'sortColumnId': 'nameOfChargee', 'columnName': 'Name of Chargee' },
            { 'columnId': 'chargeeAddress', 'sortColumnId': 'requestNumber', 'columnName': 'Address of Chargee' },
            { 'columnId': 'nameOfChargor', 'sortColumnId': 'nameOfChargor', 'columnName': 'Name of Charger' },
            { 'columnId': 'chargerAddress', 'sortColumnId': 'requestNumber', 'columnName': 'Address of Charger' },
            { 'columnId': 'governingLawOfTheCharge', 'sortColumnId': 'governingLawOfTheCharge', 'columnName': 'Governing law of Charge' },
            { 'columnId': 'statusOfTheChargeKey', 'sortColumnId': 'statusOfTheChargeKey', 'columnName': 'Status of Charge' },
            { 'columnId': 'actions', 'columnName': 'Actions' }
        ],
        ManagementAndOfficers: [
            { 'columnId': 'type', 'sortColumnId': 'requestNumber', 'columnName': 'Type' },
            { 'columnId': 'firstName', 'sortColumnId': 'requestNumber', 'columnName': 'First Name' },
            { 'columnId': 'lastName', 'sortColumnId': 'requestNumber', 'columnName': 'Last Name' },
            { 'columnId': 'titles', 'sortColumnId': 'requestNumber', 'columnName': 'Titles' },
            { 'columnId': 'dateAppointed', 'sortColumnId': 'requestNumber', 'isDate': true, 'columnName': 'Date Appointed' },
            { 'columnId': 'dateOfregistration', 'sortColumnId': 'requestNumber', 'isDate': true, 'columnName': 'Date of Registration' },
            { 'columnId': 'nationality', 'sortColumnId': 'requestNumber', 'columnName': 'Nationality' },
            { 'columnId': 'idType', 'sortColumnId': 'requestNumber', 'columnName': 'ID Type' },
            { 'columnId': 'actions', 'columnName': 'Actions' }
        ],
        EntityActivityRecords: [
            { 'columnId': 'name', 'sortColumnId': 'requestNumber', 'columnName': 'Activity Name' },
            { 'columnId': 'date', 'sortColumnId': 'requestNumber', 'isDate': true, 'columnName': 'Activity Date' },
            { 'columnId': 'fee', 'sortColumnId': 'requestNumber', 'columnName': 'Fee Amount' },
            { 'columnId': 'licenseYear', 'sortColumnId': 'requestNumber', 'columnName': 'License Year' },
            { 'columnId': 'notes', 'sortColumnId': 'requestNumber', 'columnName': 'Notes' },
            { 'columnId': 'actions', 'columnName': 'Actions' }
        ],
        EntityBusinessActivity: [
            { 'columnId': 'businessActivity', 'sortColumnId': 'requestNumber', 'columnName': 'Business Activity' },
            { 'columnId': 'subActivity', 'sortColumnId': 'requestNumber', 'columnName': 'Sub Activity' },
            { 'columnId': 'addedOn', 'sortColumnId': 'requestNumber', 'isDate': true, 'columnName': 'Added On' },
            { 'columnId': 'actions', 'columnName': 'Actions' }
        ],
        Service: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'request.requestNumber', 'columnName': 'Request No' },
            { 'columnId': 'serviceTypeName', 'sortColumnId': 'request.serviceTypeName', 'columnName': 'Service Type' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'request.registeredAgent.name', 'columnName': 'RA Name' },
            { 'columnId': 'companyNameAndSuffix', 'sortColumnId': 'companyRegistration.entityDetail.entityName.nameReservation.companyName', 'columnName': 'Entity Name' },
            { 'columnId': 'requestInternalStatusName', 'sortColumnId': 'request.requestInternalStatusKey', 'columnName': 'Status' },
            { 'columnId': 'creationTime', 'sortColumnId': 'Request.LastModificationTime', 'columnName': 'Creation Date', 'isDate': true },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        ServiceRAPortal: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'request.requestNumber', 'columnName': 'Request No' },
            { 'columnId': 'serviceTypeName', 'sortColumnId': 'request.serviceTypeName', 'columnName': 'Service Type' },
            { 'columnId': 'companyNameAndSuffix', 'sortColumnId': 'companyRegistration.entityDetail.entityName.nameReservation.companyName', 'columnName': 'Entity Name' },
            { 'columnId': 'requestExternalStatusName', 'sortColumnId': 'request.requestExternalStatusKey', 'columnName': 'Status' },
            { 'columnId': 'creationTime', 'sortColumnId': 'Request.LastModificationTime', 'columnName': 'Creation Date', 'isDate': true },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        BatchManagement: [
            { 'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch No' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'Requests.FirstOrDefault().RegisteredAgent.Name', 'columnName': 'RA' },
            { 'columnId': 'statusName', 'sortColumnId': 'StatusKey', 'columnName': 'Status' },
            { 'columnId': 'creationTime', 'sortColumnId': 'lastModificationTime', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'requestCount', 'sortColumnId': 'Requests.Count', 'columnName': 'Number of Requests' },
            { 'columnId': 'totalAmount', 'sortColumnId': 'totalAmount', 'columnName': 'Total Amount (EC)', 'isCurrency': true },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        DocumentManagement: [
            { 'columnId': 'entityName', 'sortColumnId': 'Request.CompanyName', 'columnName': 'Entity Name' },
            { 'columnId': 'entityTypeName', 'sortColumnId': 'Request.CompanyTypeName', 'columnName': 'Entity Type' },
            { 'columnId': 'incorporationNumber', 'sortColumnId': 'Request.CompanyRegistration.EntityDetail.IncorporationDetail.IncorporationNumber', 'columnName': 'Incorporation No' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'Request.RegisteredAgent.Name', 'columnName': 'RA' },
            { 'columnId': 'fileName', 'columnName': 'Document Name' },
            { 'columnId': 'creationTime', 'sortColumnId': 'creationTime', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'requestFeeCertificate', 'isDeletionDateTime': true, 'columnName': 'Deletion Date', 'subColumnId': 'lastModificationTime' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        DocumentManagementRA: [
            { 'columnId': 'entityName', 'sortColumnId': 'Request.CompanyName', 'columnName': 'Entity Name' },
            { 'columnId': 'entityTypeName', 'sortColumnId': 'Request.CompanyTypeName', 'columnName': 'Entity Type' },
            { 'columnId': 'incorporationNumber', 'sortColumnId': 'Request.CompanyRegistration.EntityDetail.IncorporationDetail.IncorporationNumber', 'columnName': 'Incorporation No' },
            { 'columnId': 'fileName', 'columnName': 'Document Name' },
            { 'columnId': 'creationTime', 'sortColumnId': 'creationTime', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'requestFeeCertificate', 'isDeletionDateTime': true, 'columnName': 'Deletion Date', 'subColumnId': 'lastModificationTime' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        RequestFeeCertificates: [
            { 'columnId': 'entityName', 'sortColumnId': 'Request.CompanyName', 'columnName': 'Entity Name' },
            { 'columnId': 'entityTypeName', 'sortColumnId': 'Request.CompanyTypeName', 'columnName': 'Entity Type' },
            { 'columnId': 'incorporationNumber', 'sortColumnId': 'Request.CompanyRegistration.EntityDetail.IncorporationDetail.IncorporationNumber', 'columnName': 'Incorporation No' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'Request.RegisteredAgent.Name', 'columnName': 'RA' },
            { 'columnId': 'fileName', 'columnName': 'Document Name' },
            { 'columnId': 'lastModificationTime', 'isDateTime': true, 'columnName': 'Modified Date' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        AnnualRenewal: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'request.requestNumber', 'columnName': 'Request No' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'request.registeredAgent.Name', 'columnName': 'RA Name' },
            { 'columnId': 'numberOfEntities', 'sortColumnId': 'numberOfEntities', 'columnName': 'Number of Entities' },
            { 'columnId': 'requestInternalStatusName', 'sortColumnId': 'request.requestInternalStatusKey', 'columnName': 'Status' },
            { 'columnId': 'creationTime', 'sortColumnId': 'request.lastModificationTime', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'actions', 'columnName': 'Actions' }
        ],
        AnnualRenewalRA: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'request.requestNumber', 'columnName': 'Request No' },
            { 'columnId': 'numberOfEntities', 'sortColumnId': 'numberOfEntities', 'columnName': 'Number of Entities' },
            { 'columnId': 'requestExternalStatusName', 'sortColumnId': 'request.requestExternalStatusKey', 'columnName': 'Status' },
            { 'columnId': 'creationTime', 'sortColumnId': 'request.lastModificationTime', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'actions', 'columnName': 'Actions' }
        ],
        UserManagement: [
            { 'columnId': 'userName', 'sortColumnId': 'userName', 'columnName': 'User Name' },
            { 'columnId': 'emailAddress', 'sortColumnId': 'emailAddress', 'columnName': 'Email', 'width': '30%' },
            { 'columnId': 'name', 'sortColumnId': 'name', 'columnName': 'First Name' },
            { 'columnId': 'surname', 'sortColumnId': 'surname', 'columnName': 'Last Name' },
            { 'columnId': 'roleDisplayNames', 'sortColumnId': 'RoleDisplayName', 'columnName': 'User Group' },
            { 'columnId': 'isActive', 'sortColumnId': 'isActive', 'columnName': 'Status', 'width': '8%' },
            { 'columnId': 'isLocked', 'sortColumnId': 'LockoutEndDateUtc', 'columnName': 'Locked', 'width': '8%' },
        ],
        Transaction: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'requestNumber', 'columnName': 'Batch/Request #' },
            { 'columnId': 'transactionNumber', 'sortColumnId': 'transactionNumber', 'columnName': 'Transaction #' },
            { 'columnId': 'transactionTypeName', 'sortColumnId': 'transactionTypeName', 'columnName': 'Transaction Type' },
            { 'columnId': 'paymentTypeName', 'sortColumnId': 'paymentTypeName', 'columnName': 'Payment Type' },
            { 'columnId': 'receivedFromName', 'sortColumnId': 'registeredAgentName', 'columnName': 'Received From' },
            { 'columnId': 'transactionAmountEC', 'sortColumnId': 'transactionAmountEC', 'columnName': 'Amount (EC)', 'width': '10%', 'isCurrency': true },
            { 'columnId': 'paymentDate', 'sortColumnId': 'paymentDate', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'status', 'sortColumnId': 'status', 'columnName': 'Status', 'width': '7%' },
            { 'columnId': 'receiptNumber', 'sortColumnId': 'receiptNumber', 'columnName': 'Receipt' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        TransactionRA: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'requestNumber', 'columnName': 'Batch/Request #' },
            { 'columnId': 'transactionNumber', 'sortColumnId': 'transactionNumber', 'columnName': 'Transaction #' },
            { 'columnId': 'transactionTypeName', 'sortColumnId': 'transactionTypeName', 'columnName': 'Transaction Type' },
            { 'columnId': 'paymentTypeName', 'sortColumnId': 'paymentTypeName', 'columnName': 'Payment Type' },
            { 'columnId': 'transactionAmountEC', 'sortColumnId': 'transactionAmountEC', 'columnName': 'Amount (EC)', 'width': '10%', 'isCurrency': true },
            { 'columnId': 'paymentDate', 'sortColumnId': 'paymentDate', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'status', 'sortColumnId': 'status', 'columnName': 'Status', 'width': '7%' },
            { 'columnId': 'receiptNumber', 'sortColumnId': 'receiptNumber', 'columnName': 'Receipt' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        TransactionHistory: [
            { 'columnId': 'transactionNumber', 'sortColumnId': 'transactionHeader.transactionNumber', 'columnName': 'Transaction #' },
            { 'columnId': 'transactionTypeName', 'sortColumnId': 'transactionHeader.transactionTypeName', 'columnName': 'Transaction Type' },
            { 'columnId': 'paymentTypeName', 'sortColumnId': 'transactionHeader.paymentTypeName', 'columnName': 'Payment Type' },
            { 'columnId': 'transactionRequestServiceTypeNames', 'sortColumnId': 'transactionHeader.transactionRequestServiceTypeNames', 'columnName': 'Service Type' },
            { 'columnId': 'receivedFromName', 'sortColumnId': 'transactionHeader.registeredAgentName, transactionHeader.receivedFromName', 'columnName': 'Received From' },
            { 'columnId': 'transactionRequestEntityNames', 'sortColumnId': 'transactionHeader.companyName, transactionHeader.transactionRequestEntityNames', 'columnName': 'Entity Name' },
            { 'columnId': 'feeDescription', 'sortColumnId': 'description', 'columnName': 'Fees' },
            { 'columnId': 'accountNumberAndName', 'sortColumnId': 'financialAccount.accountNumber', 'columnName': 'GL Account' },
            { 'columnId': 'amountEC', 'sortColumnId': 'amount', 'columnName': 'Amount (EC)', 'isCurrency': true },
            { 'columnId': 'paymentDate', 'sortColumnId': 'transactionHeader.paymentDate', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'status', 'sortColumnId': 'transactionHeader.status', 'columnName': 'Status' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        TransactionHistoryRAPortal: [
            { 'columnId': 'transactionNumber', 'sortColumnId': 'transactionHeader.transactionNumber', 'columnName': 'Transaction #' },
            { 'columnId': 'transactionTypeName', 'sortColumnId': 'transactionHeader.transactionTypeName', 'columnName': 'Transaction Type' },
            { 'columnId': 'paymentTypeName', 'sortColumnId': 'transactionHeader.paymentTypeName', 'columnName': 'Payment Type' },
            { 'columnId': 'transactionRequestServiceTypeNames', 'sortColumnId': 'transactionHeader.transactionRequestServiceTypeNames', 'columnName': 'Service Type' },
            { 'columnId': 'transactionRequestEntityNames', 'sortColumnId': 'transactionHeader.companyName, transactionHeader.transactionRequestEntityNames', 'columnName': 'Entity Name' },
            { 'columnId': 'feeDescription', 'sortColumnId': 'description', 'columnName': 'Fees' },
            { 'columnId': 'paymentDate', 'sortColumnId': 'transactionHeader.paymentDate', 'isDate': true, 'columnName': 'Creation Date' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        AccountBalance: [
            { 'columnId': 'registeredAgentAccountNumber', 'sortColumnId': 'registeredAgentAccountNumber', 'columnName': 'Account #' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'registeredAgentName', 'columnName': 'RA Name' },
            { 'columnId': 'balanceEC', 'sortColumnId': 'balanceEC', 'columnName': 'Prepaid Account Balance (in EC)', 'isCurrency': true },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        AccountHistory: [
            { 'columnId': 'transactionNumber', 'sortColumnId': 'transactionHeader.transactionNumber', 'columnName': 'Transaction #' },
            { 'columnId': 'transactionTypeName', 'sortColumnId': 'transactionHeader.transactionTypeName', 'columnName': 'Transaction Type' },
            { 'columnId': 'paymentTypeName', 'sortColumnId': 'transactionHeader.paymentTypeName', 'columnName': 'Payment Type' },
            { 'columnId': 'transactionRequestServiceTypeNames', 'sortColumnId': 'transactionHeader.transactionRequestServiceTypeNames', 'columnName': 'Service Type' },
            { 'columnId': 'feeDescription', 'sortColumnId': 'fee.feeDescription', 'columnName': 'Fees' },
            { 'columnId': 'transactionRequestEntityNames', 'sortColumnId': 'transactionHeader.transactionRequestEntityNames', 'columnName': 'Entity Name' },
            { 'columnId': 'amountEC', 'sortColumnId': 'amount', 'columnName': 'Amount (EC)', 'isCurrency': true },
            { 'columnId': 'status', 'sortColumnId': 'transactionHeader.status', 'columnName': 'Status' },
            { 'columnId': 'balance', 'sortColumnId': 'balance', 'columnName': 'Balance (EC)', 'isCurrency': true },
            { 'columnId': 'paymentDate', 'sortColumnId': 'transactionHeader.paymentDate', 'isDate': true, 'columnName': 'Creation Date' },
        ],
        Dashboard: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'requestNumber', 'columnName': 'Request No', 'width': '10%' },
            //{'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch Number'},
            { 'columnId': 'serviceTypeName', 'sortColumnId': 'serviceTypeName', 'columnName': 'Service Type', 'width': '10%' },
            { 'columnId': 'companyName', 'sortColumnId': 'companyName', 'columnName': 'Entity Name', 'width': '10%' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'companyTypeName', 'columnName': 'Entity Type', 'width': '10%' },
            { 'columnId': 'registeredAgentNumber', 'sortColumnId': 'RegisteredAgent.AccountNumber', 'columnName': 'RA#', 'width': '10%' },
            { 'columnId': 'registeredAgentName', 'sortColumnId': 'RegisteredAgent.Name', 'columnName': 'RA Name', 'width': '10%' },
            { 'columnId': 'requestInternalStatusName', 'sortColumnId': 'requestInternalStatusKey', 'columnName': 'Status', 'width': '10%' },
            { 'columnId': 'assignedTo', 'sortColumnId': 'assignedTo', 'columnName': 'Assigned To', 'width': '10%' },

            { 'columnId': 'lastModificationTime', 'isDate': true, 'sortColumnId': 'lastModificationTime', 'columnName': 'Modification Date', 'width': '10%' },
            { 'columnId': 'creationTime', 'isDate': true, 'sortColumnId': 'creationTime', 'columnName': 'Creation Date', 'width': '10%' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        DashboardRAPortal: [
            { 'columnId': 'requestNumber', 'sortColumnId': 'requestNumber', 'columnName': 'Request No', 'width': '10%' },
            //{'columnId': 'batchNumber', 'sortColumnId': 'batchNumber', 'columnName': 'Batch Number'},
            { 'columnId': 'serviceTypeName', 'sortColumnId': 'serviceTypeName', 'columnName': 'Service Type', 'width': '10%' },
            { 'columnId': 'companyName', 'sortColumnId': 'companyName', 'columnName': 'Entity Name', 'width': '10%' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'companyTypeName', 'columnName': 'Entity Type', 'width': '10%' },
            //{ 'columnId': 'raAccountNumber','sortColumnId': 'raAccountNumber', 'columnName': 'RA#' , 'width': '10%'},
            //{ 'columnId': 'raName','sortColumnId': 'raName', 'columnName': 'RA Name' , 'width': '10%'},
            { 'columnId': 'requestExternalStatusName', 'sortColumnId': 'requestExternalStatusKey', 'columnName': 'Status', 'width': '10%' },
            //{ 'columnId': 'assignedTo','sortColumnId': 'assignedTo', 'columnName': 'Assigned To' , 'width': '10%'},

            { 'columnId': 'lastModificationTime', 'isDate': true, 'sortColumnId': 'lastModificationTime', 'columnName': 'Modification Date', 'width': '10%' },
            { 'columnId': 'creationTime', 'isDate': true, 'sortColumnId': 'creationTime', 'columnName': 'Creation Date', 'width': '10%' },
            { 'columnId': 'actions', 'columnName': 'Actions', 'width': '6%' }
        ],
        RaManagement: [
            { 'columnId': 'accountNumber', 'sortColumnId': 'accountNumber', 'columnName': 'Account Number', 'width': '18%' },
            { 'columnId': 'name', 'sortColumnId': 'name', 'columnName': 'Name' },
            { 'columnId': 'email', 'sortColumnId': 'email', 'columnName': 'Email' },
            { 'columnId': 'businessPhoneNumber', 'sortColumnId': 'businessPhoneNumber', 'columnName': 'Business Phone No.', 'width': '19%' },
            { 'columnId': 'faxNumber', 'sortColumnId': 'faxNumber', 'columnName': 'Fax Number', 'width': '18%' },
            { 'columnId': 'isActive', 'sortColumnId': 'isActive', 'columnName': 'Active', 'width': '10%' }
        ],
        FeeManagement: [
            { 'columnId': 'feeCode', 'sortColumnId': 'feeCode', 'columnName': 'Code', 'width': '9%', "printCellWidth": 50 },
            { 'columnId': 'feeDescription', 'sortColumnId': 'feeDescription', 'columnName': 'Particulars', 'width': '15%' },
            { 'columnId': 'serviceTypes', 'sortColumnId': 'serviceTypeName', 'columnName': 'Service Types', 'isArray': true, 'subColumnId': 'serviceTypeName', 'width': '15%' },
            { 'columnId': 'companyTypeName', 'sortColumnId': 'companyTypeName', 'columnName': 'Entity Type', 'width': '14%', "printCellWidth": 100 },
            { 'columnId': 'amount', 'sortColumnId': 'amount', 'columnName': 'Fee (EC)', 'width': '11%', 'isCurrency': true, "printCellWidth": 60 },
            { 'columnId': 'alternativeAmount', 'sortColumnId': 'alternativeAmount', 'columnName': 'Fee (USD)', 'width': '12%', 'isCurrency': true, "printCellWidth": 60 },
            { 'columnId': 'financialAccountNumberWithDescription', 'sortColumnId': 'financialAccountNumber', 'columnName': 'GL Account' },
            //{ 'columnId': 'financialAccountDescription', 'sortColumnId': 'financialAccountDescription', 'columnName': 'GL Account Description' },
            { 'columnId': 'isActive', 'sortColumnId': 'isActive', 'columnName': 'Status', 'width': '10%', "printCellWidth": 50 }
        ],
        FinancialAccountManagement: [
            { 'columnId': 'accountNumber', 'sortColumnId': 'accountNumber', 'columnName': 'Account Number' },
            { 'columnId': 'description', 'sortColumnId': 'description', 'columnName': 'Account Description' },
            { 'columnId': 'financialAccountTypeName', 'columnName': 'Account Type', 'sortColumnId': 'financialAccountTypeKey' },
            { 'columnId': 'isActive', 'sortColumnId': 'isActive', 'columnName': 'Active' }
        ],
        BusinessActivities: [
            { 'columnId': 'companyTypeName', 'sortColumnId': 'companyTypeName', 'columnName': 'Entity Type' },
            { 'columnId': 'name', 'sortColumnId': 'name', 'columnName': 'Business Activity' },
            { 'columnId': 'childBusinessActivities', 'sortColumnId': 'childBusinessActivities.Count()', 'columnName': 'Sub Activity', 'isArray': true, 'subColumnId': 'name', "printCellWidth": 200 },
            { 'columnId': 'beps', 'sortColumnId': 'beps', 'columnName': 'BEPS', "printCellWidth": 50 },
            { 'columnId': 'isActive', 'sortColumnId': 'isActive', 'columnName': 'Active', "printCellWidth": 50 }
        ],
        EntityStatusReport: [
            { 'columnId': 'entityStatus', 'sortColumnId': 'entityStatus', 'columnName': 'Entity Status' },
            { 'columnId': 'ibcCount', 'sortColumnId': 'ibcCount', 'columnName': 'IBC' },
            { 'columnId': 'ibcInsuranceCount', 'sortColumnId': 'ibcInsuranceCount', 'columnName': 'IBC Insurance' },
            { 'columnId': 'llcCount', 'sortColumnId': 'llcCount', 'columnName': 'LLC' },
            { 'columnId': 'foundationCount', 'sortColumnId': 'foundationCount', 'columnName': 'Foundations' },
            { 'columnId': 'internationalTrustCount', 'sortColumnId': 'internationalTrustCount', 'columnName': 'International Trusts' },
            { 'columnId': 'qualifiedForeignTrustCount', 'sortColumnId': 'qualifiedForeignTrustCount', 'columnName': 'Qualified Foreign Trusts' },
            { 'columnId': 'total', 'sortColumnId': 'total', 'columnName': 'Total' }
        ],
        IncorporationStatisticsReport: [
            { 'columnId': 'date', 'sortColumnId': 'date', 'columnName': 'Month' },
            { 'columnId': 'ibcCount', 'sortColumnId': 'ibcCount', 'columnName': 'IBC' },
            { 'columnId': 'ibcInsuranceCount', 'sortColumnId': 'ibcInsuranceCount', 'columnName': 'IBC Insurance' },
            { 'columnId': 'llcCount', 'sortColumnId': 'llcCount', 'columnName': 'LLC' },
            { 'columnId': 'foundationCount', 'sortColumnId': 'foundationCount', 'columnName': 'Foundations' },
            { 'columnId': 'internationalTrustCount', 'sortColumnId': 'internationalTrustCount', 'columnName': 'International Trusts' },
            { 'columnId': 'qualifiedForeignTrustCount', 'sortColumnId': 'qualifiedForeignTrustCount', 'columnName': 'Qualified Foreign Trusts' },
            { 'columnId': 'total', 'sortColumnId': 'total', 'columnName': 'Total' }
        ],
        RegistryStatisticsReport: [
            { 'columnId': 'measure', 'sortColumnId': 'measure', 'columnName': 'Measure', 'width': '75%' },
            { 'columnId': 'total', 'sortColumnId': 'total', 'columnName': 'Total' }
        ],
        RevenueSummaryReport: [
            { 'columnId': 'financialAccountNumber', 'sortColumnId': 'financialAccountNumber', 'columnName': 'Code' },
            { 'columnId': 'financialAccountName', 'sortColumnId': 'financialAccountName', 'columnName': 'GL Account' },
            { 'columnId': 'amount', 'sortColumnId': 'amount', 'columnName': 'Amount', 'isCurrency': true },
        ],
        RevenueDetailReport: [
            { 'columnId': 'receiptNumber', 'sortColumnId': 'receiptNumber', 'columnName': 'Receipt Number' },
            { 'columnId': 'receiptDate', 'sortColumnId': 'receiptDate', 'columnName': 'Receipt Date' },
            { 'columnId': 'accountName', 'sortColumnId': 'accountName', 'columnName': 'Account Name' },
            { 'columnId': 'amount', 'sortColumnId': 'amount', 'columnName': 'Amount', 'isCurrency': true },
        ],
        DepositSummaryReport: [
            { 'columnId': 'paymentMethod', 'sortColumnId': 'paymentMethod', 'columnName': 'Payment Method' },
            { 'columnId': 'numberOfTransactions', 'sortColumnId': 'numberOfTransactions', 'columnName': 'Number of Transactions' },
            { 'columnId': 'amount', 'sortColumnId': 'amount', 'columnName': 'Amount', 'isCurrency': true },
        ],
        DepositDetailReport: [
            { 'columnId': 'receiptNumber', 'sortColumnId': 'receiptNumber', 'columnName': 'Receipt Number' },
            { 'columnId': 'receivedFrom', 'sortColumnId': 'receivedFrom', 'columnName': 'Received From' },
            { 'columnId': 'amount', 'sortColumnId': 'amount', 'columnName': 'Amount', 'isCurrency': true },
            { 'columnId': 'paymentMethod', 'sortColumnId': 'paymentMethod', 'columnName': 'Payment Method' },
            { 'columnId': 'documentNumber', 'sortColumnId': 'documentNumber', 'columnName': 'Doc #' },
            { 'columnId': 'bank', 'sortColumnId': 'bank', 'columnName': 'Bank' },
            { 'columnId': 'fees', 'sortColumnId': 'fees', 'columnName': 'General Description of Payment', 'width': '20%' }
        ],
        ChangeHistory: [
            { columnName: "Update on", columnId: "changeTime", type: "dateTime", format: "dd-MM-yyyy hh:mm aa", },
            { columnName: "Updated by", columnId: "userName" },
            { columnName: "Updated from", columnId: "portalName" },
            { columnName: "Field", columnId: "propertyName" },
            { columnName: "Old Value", columnId: "originalValue" },
            { columnName: "New Value", columnId: "newValue" },
        ],
    };

    static readonly PortalKey = {
        CR: "Portal.CR",
        RA: "Portal.RA"
    };

    static readonly Permissions = {
        Accounting_Transaction_Edit: "Accounting - Transaction Management.Edit",
        Accounting_Transaction_View: "Accounting - Transaction Management.View",
        AnnualRenewal_Edit: "Annual Renewal.Edit",
        CompanyRegistration_Edit: "Company Registrations.Edit",
        CompanyRegistration_Edit_FeeAndAssignment: "Company Registrations.EditFeePaymentAssignment",
        CompanyRegistration_View: "Company Registrations.View",
        IBC_Insurance_View: "IBC Insurance.View",
        IBC_Insurance_Edit: "IBC Insurance.Edit",
        Dashboard_View: "Home Dashboard.View",
        MasterData_Fee_Edit: "Master Data - Fee Management.Edit",
        MasterData_Fee_View: "Master Data - Fee Management.View",
        MasterData_RA_Edit: "Master Data - Registered Agents.Edit",
        MasterData_RA_View: "Master Data - Registered Agents.View",
        MasterData_Account_Edit: "Master Data - Financial Account Management.Edit",
        MasterData_Account_View: "Master Data - Financial Account Management.View",
        MasterData_BusinessActivity_View: "Master Data - Business Activity.View",
        MasterData_BusinessActivity_Edit: "Master Data - Business Activity.Edit",
        NameReservation_Edit: "Name Reservation.Edit",
        NameReservation_View: "Name Reservation.View",
        Portal_RegisteredAgent: "Portal.Registered Agent",
        Portal_CR: "Portal.Corporate Registry",
        Reports_Revenue: "Reports.Revenue.View",
        Reports_Deposit: "Reports.Deposit.View",
        Security_Edit: "Security.Edit",
        ServicesAndBatches_Edit: "Services and Batches.Edit",
        Signature_Authority: "Signature Authority",
    };

    static readonly ServiceCategories = {
        Registration: "ServiceCategory.Registration",
        ServiceRequest: "ServiceCategory.ServiceRequest",
        NameReservation: "ServiceCategory.NameReservation",
        AnnualRenewal: "ServiceCategory.Renewal",
    };

    static readonly walkInService: string = "WalkInService";

    static readonly InsuranceClassStatus = {
        ApplicationApproved: "ApplicationApproved",
        ApplicationRejected: "ApplicationRejected",
        NotRegistered: "NotRegistered",
        Registered: "Registered",
    };

    static readonly GainLossFinancialAccountNumber = "15";
    static readonly AllIbcInsuranceClassKeys: string = "All";

    static readonly DocumentConfidentialityKeys = {
        Unspecified: "Unspecified",
        Public: "Public",
        Private: "Private",
    };

    static readonly LookupDataKeys = {
        ActivityCode: "ActivityCode",
        IbcInsuranceClass: "IbcInsuranceClass",
        DocumentConfidentiality: "DocumentConfidentiality",
        DocumentStatus: "DocumentStatus",
        Seals: "Seals",
        TypesOfSharesAllowed: "TypesOfSharesAllowed",
    };

    static readonly ValidationErrorMessages = {
        At_least_one_request_should_be_selected_: "At least one request should be selected.",
        The_selected_requests_belong_to_different_RAs_and_can_t_be_combined_in_the_same_batch_: "The selected requests belong to different RAs and can’t be combined in the same batch.",
    };

    static readonly DialogHeaders = {
        Search_Requests: "Search Requests",
    };

    static readonly PaymentTypes = {
        OnlinePayment: "FinanceService.PaymentType.OnlinePayment",
    };

    static readonly TagKeys = {
        RegistrationIBCInsurance: "RegistrationIBCInsurance",
    };

    static labelPrinterSettings: ILabelPrinterSettings;
    static title: string;
    static paymentGateway: IPaymentGateway;
}

export interface IPaymentGateway {
    isEnabled: boolean;
    key: string;
    url: string;
}
